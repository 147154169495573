@font-face {
  font-family: "Circular-Black";
  src: url("../assets/fonts/CircularStd-Black.woff2") format("woff2"),
    url("../assets/fonts/CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular-Bold";
  src: url("../assets/fonts/CircularStd-Bold.woff2") format("woff2"),
    url("../assets/fonts/CircularStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular-Medium";
  src: url("../assets/fonts/CircularStd-Medium.woff2") format("woff2"),
    url("../assets/fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular-Book";
  src: url("../assets/fonts/CircularStd-Book.woff2") format("woff2"),
    url("../assets/fonts/CircularStd-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


$prime_red: #ef737f;
$prime_blue: #6e5f7e;
$text_gray: #999997;
$text_red: #be1622;
$text_green: #009640;
$bg_gray: #f7f7f7;
$charcoal: #3c3d3a;

$book: "Circular-Book";
$medium: "Circular-Medium";
$bold: "Circular-Bold";
$black: "Circular-Black";

/* MIXINS */

@mixin mq($size) {
    @if $size==xs {
      @media (max-width: 320px) {
        @content;
      }
    } @else if $size==mobile {
      @media (max-width: 767px) {
        @content;
      }
    } @else if $size==tab {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        @content;
      }
    } @else if $size==desktop {
      @media only screen and (min-width: 1025px) {
        @content;
      }
    }
  }
  
  @mixin flexbox {
    display: -webkit-box; // old
    display: -moz-box; // old
    display: -ms-flexbox; // ie
    display: -webkit-flex; // new
    display: flex; // new
  }

  body{
    margin: 0px;
    font-family: $book!important;
  }

  main{
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .img-responsive {
    max-width: 100%;
  height: auto;
  }

  .dashboard-table{
    box-shadow:none;
  }

  .dashboard-wrap{
    .MuiPaper-root{
     box-shadow:none!important;
     }
  }

  

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  
  .navbar {
    //height: 60px;
    background-color: #fff;
    position: relative;
    padding: 10px ;
    
    .content{
      display: flex;
      align-items: center;
      //justify-content: space-between;
    }

    @include mq(desktop){
      border-bottom: 1px solid #e0e0e0;
      .content{
        @include flexbox();
        align-items: center;
        //justify-content: space-between;
      }
    }
  }
  
  .main-logo {
    //max-width: 64px;
    @include flexbox();
    align-items: baseline;
    gap: 20px;
    cursor: pointer;
    text-decoration: none;
    span{
      padding-left: 40px;
      font-size: 32px;
      font-weight: 600;
      background: linear-gradient(90deg,$prime_blue,$prime_red);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: $bold;
      white-space: nowrap;
    }

    @include mq(mobile){
      span{
        font-size: 20px;
        padding-left: 5px;
      }
    }
  }
  
  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  
    li:not(:last-child) {
      margin-right: 60px;
    }
  
    a {
      font-size: 16px;
      font-weight: 400;
      color: white;
      text-decoration: none;
  
      &.active {
        color: $prime_red;
        font-weight: 500;
        position: relative;
  
        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #574c4c;
        }
      }
    }
  }

  .navbar-overlay{
    opacity: 0;
    z-index: -1;
  }

  .navbar-overlay.active{
    opacity: 1;
    z-index: 100;
    background-color: rgba(15,23,42,.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    z-index: 100000;
    @include mq(desktop){
      padding-right: 20px;
    }

    .fa{
      font-size: 22px;
    }
  }

  .nav-elements {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #3c3d3a;
    z-index: 1000;
    width: 0px;
    height: calc(100vh);
    transition: all 0.3s ease-in;
    overflow: hidden;

    

    ul {
      display: flex;
      flex-direction: column;

      li {
        margin-right: unset;
        margin-top: 22px;
        opacity: 0;
        transition: all 0.2s ease;
      }
    }
    &.active {
      width: 270px;
      li{
        opacity: 1;
      }
    }
  }

  



/* FOOTER */

.footer {
  @include flexbox();
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 15px;
  margin-bottom: 40px;
  .meta {
    font-size: 14px;
    color: $text_gray;
    transition: all 0.3s ease;
    a{
      text-decoration: none;
      color: $prime_blue;
    }
    a:hover {
      color: $prime_red;
      outline: none;
    }
    a:visited,
    a:focus {
      outline: none;
    }
  }
  .social {
    .link {
      color: $text_gray;
      transition: all 0.3s ease;
      text-decoration: none;
      &:hover,
      &:visited,
      &:focus {
        outline: none;
      }
      &:hover {
        color: $prime_red;
      }
    }
    .link:not(:last-child) {
      margin-right: 10px;
    }
  }
}
  

  .MuiTableCell-root {
    font-family: $book!important;
  }

  .MuiInputBase-input{
    font-size: 14px!important;
  }

  .MuiTableRow-head{
    background: $charcoal!important;
  }
  
  .Mui-TableHeadCell-Content-Wrapper, .Mui-active .MuiTableSortLabel-icon{
    color: white!important;
  }

  .css-1vlry5a-MuiTableContainer-root{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)!important; 
  }

  .css-1cyhbc3-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
    color: #ffffff42!important;
  }

  .css-929hxt-MuiInputBase-input-MuiInput-input, .css-1cyhbc3-MuiButtonBase-root-MuiIconButton-root{
   // color: white!important;
  }

  .css-194a1fa-MuiSelect-select-MuiInputBase-input{
    color: black!important;
  }

  .MuiBox-root.css-sq9qdz{
    padding: 0;
  }

  .MuiFormHelperText-root {
    color: red !important;
  }

  .MuiBox-root.css-1cjiko4{
    align-items: center;
  }

  .MuiTableContainer-root.css-1vlry5a-MuiTableContainer-root{
    margin-top: 10px;
  }

  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd{
    padding: 10px 0px;
  }

  .dashboard-wrap{
    margin-top: 40px;
    margin-bottom: 40px;

    .MuiTableContainer-root{
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)!important; 
    }

    .MuiCollapse-wrapperInner.MuiCollapse-vertical {
      .MuiInputBase-inputAdornedEnd {
        color: white!important;
      }
    }
    .Mui-disabled.MuiIconButton-root.Mui-disabled{
      color: #ffffff42!important;
    }
    .MuiBox-root {
      .MuiIconButton-root svg[data-testid="FilterAltIcon"]{
        color: white!important;
      }
    }

  }



  /* Common */
  
  .btn-common{
    color: $charcoal;
    background: #fff;
    border: 1px solid $charcoal;
    transition: all .3s ease;
    margin-bottom: 5px;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
      background: linear-gradient(90deg,$prime_blue,$prime_red);
      color: #fff;
      border: 1px solid #6e5f7e;
    }

    @include mq(mobile){
      font-size: 14px;
      padding: 4px 10px;
    }
  }

  .btn-common.btn-dark{
      background: $charcoal;
      color: #fff;
      &:hover{
        color: $charcoal;
        background: #fff;
      }
  }

  .new-account-form{
    @include flexbox();
    flex-direction: column;
    gap: 10px;
    .cta-wrap{
      margin-top: 20px;
      @include flexbox();
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    @include mq(desktop){
      min-width: 360px;
    }
  }


  .methodology{
    h2{
      color: $prime_blue;
    }
    a{
      text-decoration: none;
      color: $prime_blue;
    }
    a:hover {
      color: $prime_red;
      outline: none;
    }
  }

  .menus{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
    //justify-content: center;
    padding: 0;
  }

  .menu-items{
    flex-basis: calc(33.33% - 20px);
    a{
      text-decoration: none;
      color: $charcoal;
      &:hover{
        color: $prime_red;
      }
    }
  }

  #menu{
    ul{
      display: flex;
      flex-wrap: wrap;
      width: 80vw;
      li{
        flex-basis: 33%;
      }
    }

    @include mq(mobile){
      ul{
        li{
          flex-basis: 100%;
        }
      }
    }
  }